/* eslint-disable react/jsx-no-useless-fragment */
import { ChangeEvent, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import MissingProductIcon from 'src/components/common/MissingProductIcon';
import TextField from 'src/components/common/inputs/TextField';
import TranslationKeySelect from 'src/components/common/TranslationKeySelect';
import { EditContext } from 'src/contexts/EditContext';
import Card, {
    CardHeader,
    CardContent,
} from 'src/components/common/Card';
import { updateGallery } from 'src/store/galleryData/galleryStore';
import { NULL_SELECT_OPTION, TRANSLATION_PURPOSE } from 'src/constants';
import ManageGalleryNotificationChannels from 'src/components/common/inputs/ManageNotificationChannels';
import useGalleryNotificationChannelData from 'src/hooks/useGalleryNotificationChannelData';
import ZeroState from 'src/components/common/ZeroState';
import ManageGalleryNotificationChannelsContext from 'src/contexts/ManageGalleryNotificationChannelContext';
import ManageGalleryNotificationChannelsController from 'src/components/common/inputs/ManageNotificationChannels/ManageGalleryNotificationChannelsController';
import ManageForcedRankingStrategy from 'src/components/common/ManageRankingStrategy/ManageForcedRankingStrategy';

const ConfigDetails = (): JSX.Element => {
    const context = useContext(ManageGalleryContext);
    const { editing } = useContext(EditContext);
    const { galleryData, dispatch } = context;
    const { coreProduct, gallery } = galleryData;
    const [state, loading, error] = useGalleryNotificationChannelData(gallery.id);

    if (error) {
        // TODO better error handling?
        throw error;
    }

    const handleTranslationKeyChange = (
        key: string,
    ): void => {
        dispatch(updateGallery({
            ...gallery,
            h1Title: key,
        }));
    };

    const handleProductKeyChange = (
        event: ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        event.preventDefault();

        dispatch(updateGallery({
            ...gallery,
            productKey: event.target.value.trim(),
        }));
    };

    const handleMPVChange = (
        event: ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        event.preventDefault();

        dispatch(updateGallery({
            ...gallery,
            mpv: event.target.value,
        }));
    };

    const handleForcedRankingStrategy = (
        strategyName: string,
    ): void => {
        dispatch(updateGallery({
            ...gallery,
            forcedRankingStrategy: strategyName === NULL_SELECT_OPTION ? null : strategyName,
        }));
    };

    return (
        <Card>
            <CardHeader>
                Gallery Config Details
            </CardHeader>
            <CardContent>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid xs={6}>
                        <TextField
                            fullWidth
                            id="gallery-id-input"
                            value={gallery.id}
                            label="Gallery ID"
                        />
                    </Grid>
                    <Grid xs={6}>
                        <TextField
                            fullWidth
                            editing={editing}
                            id="product-key-input"
                            placeholder="Product Key"
                            value={gallery.productKey}
                            label={(
                                <span>
                                    {(!!gallery.productKey && !coreProduct) ? <MissingProductIcon /> : null}
                                    <span>Product Key</span>
                                </span>
                            )}
                            onChange={handleProductKeyChange}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <TextField
                            fullWidth
                            editing={editing}
                            id="mpv-input"
                            placeholder="MPV"
                            value={gallery.mpv}
                            label="MPV"
                            onChange={handleMPVChange}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <TranslationKeySelect
                            fullWidth
                            editing={editing}
                            id="h1-title-input"
                            placeholder="H1 Title"
                            label="H1 Title"
                            handleTranslationKeyChange={handleTranslationKeyChange}
                            value={gallery.h1Title}
                            defaultPurpose={TRANSLATION_PURPOSE.H1Title}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <ManageForcedRankingStrategy
                            editing={editing}
                            currentStrategy={gallery.forcedRankingStrategy ?? NULL_SELECT_OPTION}
                            setStrategy={handleForcedRankingStrategy}
                        />
                    </Grid>
                    <Grid xs={6}>
                        {loading && (<></>)}
                        {(!loading && !state) && (
                            <ZeroState text="Could not load the Notification Channels For this Gallery" />
                        )}
                        {(!loading && state) && (
                            <ManageGalleryNotificationChannelsContext.Provider value={state}>
                                <ManageGalleryNotificationChannelsController>
                                    <ManageGalleryNotificationChannels
                                        editing={editing}
                                        notificationChannels={
                                            state.galleryNotificationChannelData.galleryNotificationChannels
                                        }
                                    />
                                </ManageGalleryNotificationChannelsController>
                            </ManageGalleryNotificationChannelsContext.Provider>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ConfigDetails;
