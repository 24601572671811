import { useEffect, useState } from 'react';

import useRankingStrategies from 'src/hooks/useRankingStrategies';
import { NULL_SELECT_OPTION } from 'src/constants';
import ManageRankingStrategy from 'src/components/common/ManageRankingStrategy';

interface PropTypes {
    editing: boolean;
    currentStrategy: string | null;
    setStrategy: (strategyName: string) => void;
}

const STRATEGY_TYPE = 'Forced';

const ManageForcedRankingStrategy = (props: PropTypes): JSX.Element => {
    const {
        editing,
        currentStrategy,
        setStrategy,
    } = props;

    const [rankingStrategies, loading, error] = useRankingStrategies();
    const [strategies, setStrategies] = useState(rankingStrategies);

    useEffect(() => {
        if (rankingStrategies && rankingStrategies.length > 0) {
            const sortedStrategies = rankingStrategies.toSorted();

            if (!sortedStrategies.includes(NULL_SELECT_OPTION)) {
                sortedStrategies.unshift(NULL_SELECT_OPTION);
            }

            setStrategies(sortedStrategies);
        }
    }, [rankingStrategies, loading]);

    return (
        <ManageRankingStrategy
            editing={editing}
            strategyType={STRATEGY_TYPE}
            currentStrategy={currentStrategy}
            setStrategy={setStrategy}
            strategies={strategies}
            loading={loading}
            error={error}
        />
    );
};

export default ManageForcedRankingStrategy;
