import { MouseEvent, useContext, useState } from 'react';
import {
    Grid,
    Typography,
    Box,
    Button,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import NotificationChannelChipsArray from 'src/components/common/inputs/ManageNotificationChannels/NotificationChannelChipsArray';
import LabelSelect from 'src/components/common/inputs/ManageNotificationChannels/LabelSelect';
import useCompatibleBreakpoint from 'src/hooks/useCompatibleBreakpoint';
import useNotificationChannelSearch from 'src/hooks/useNotificationChannelSearch';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';

interface PropTypes {
    notificationChannels: Services.NotificationChannels.NotificationChannel[];
    onAdd: (galleryNotificationChannels: Services.NotificationChannels.NotificationChannel[]) => void;
    onDelete: (galleryNotificationChannels: Services.NotificationChannels.NotificationChannel[]) => void;
}

const compareNotificationChannels = (
    a: Services.NotificationChannels.NotificationChannel,
    b: Services.NotificationChannels.NotificationChannel,
): boolean => {
    if (a.id === b.id) {
        return true;
    }
    return false;
};

const EditNotificationChannels = (props: PropTypes): JSX.Element => {
    const {
        notificationChannels,
        onAdd,
        onDelete,
    } = props;
    const stubNotificationChannel = {
        label: 'n/a',
        id: -1,
    } as Services.NotificationChannels.NotificationChannel;
    const [
        notificationChannelToAdd,
        setNotificationChannelToAdd,
    ] = useState<Services.NotificationChannels.NotificationChannel>(stubNotificationChannel);
    const splitScreenProp = { [useCompatibleBreakpoint('md')]: 6 };
    const { galleryData } = useContext(ManageGalleryContext);
    const { gallery } = galleryData;
    const notificationChannelsSearch = useNotificationChannelSearch();
    const {
        searchedNotificationChannels,
    } = notificationChannelsSearch;

    const validChannels = searchedNotificationChannels.filter((searchedChannel) => {
        const x = notificationChannels.filter((nc) => compareNotificationChannels(searchedChannel, nc));

        return !!(x.length < 1);
    }).sort((nc1) => {
        const ncA = nc1.defaultFor.toLowerCase();

        if (ncA === gallery.securityBoundary.toLowerCase()) {
            return 0;
        }
        return 1;
    });

    const fullOptions = [
        stubNotificationChannel,
        ...validChannels,
    ];

    const handleAdd = (event: MouseEvent<{}>): void => {
        event.preventDefault();

        if (notificationChannelToAdd.id !== -1) {
            const updatedGalleryNotificationChannels = [
                ...notificationChannels,
                notificationChannelToAdd,
            ];

            onAdd(updatedGalleryNotificationChannels);

            setNotificationChannelToAdd(stubNotificationChannel);
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid
                container
                item
                direction="column"
                wrap="nowrap"
                spacing={1}
                xs={12}
                {...splitScreenProp}
            >
                <Grid item>
                    <Typography gutterBottom={false} variant="overline">
                        <Box fontWeight="bold">
                            <Typography
                                display="inline"
                                variant="inherit"
                            >
                                Notification Channels
                            </Typography>
                        </Box>
                    </Typography>
                </Grid>
                <Grid item>
                    <NotificationChannelChipsArray
                        editing
                        chipsData={notificationChannels}
                        onDelete={onDelete}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                item
                alignItems="center"
                wrap="nowrap"
                spacing={2}
                xs={12}
                {...splitScreenProp}
            >
                <Grid item xs>
                    <LabelSelect
                        currentNotificationChannel={notificationChannelToAdd}
                        setCurrentNotificationChannel={setNotificationChannelToAdd}
                        options={fullOptions}
                    />
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={(<Add />)}
                        onClick={handleAdd}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditNotificationChannels;
