import { useEffect, useState, useContext } from 'react';

import forcedRankingService from 'src/services/ForcedRankingStrategyService';
import { getLogger } from 'src/utils/logger';
import { AppError } from 'src/lib/errors';
import { IdentityContext } from 'src/contexts/IdentityContext';

type RankingStrategiesHook = [
    string[] | undefined,
    boolean,
    AppError | null,
];

const useRankingStrategies = (): RankingStrategiesHook => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [strategies, setStrategies] = useState<string[]>();
    const { accessToken } = useContext(IdentityContext);

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            if (accessToken) {
                try {
                    setLoading(true);
                    const strategyNames = await forcedRankingService.getForcedRankingStrategies(accessToken);

                    setStrategies(strategyNames);
                } catch (e: any) {
                    getLogger().error(e);
                    setError(new AppError(e));
                } finally {
                    setLoading(false);
                }
            }
        };

        runEffect();
    }, [accessToken]);

    return [strategies, loading, error];
};

export default useRankingStrategies;
