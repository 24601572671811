import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets Forced Ranking Strategies from Ingest Configuration Service
 */
class ForcedRankingStrategyService extends AbstractService {
    constructor() {
        super(config.services.ingestConfiguration);
    }

    public async getForcedRankingStrategies(bearerToken: string):
    Promise<string[]> {
        const requestUrl = 'api/v1/forcedRankingStrategies';

        try {
            const response = await this.api.get<string[]>(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                        noCache: true,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new ForcedRankingStrategyService();
